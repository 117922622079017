const routes = [{
	path: '/',
	name: 'shieldHome',
	component: () => import('@/views/shieldHome/shieldHome')
},	
{
	path: '/shield',
	name: 'shieIndex',
	component: () => import('@/views/shieIndex/shieIndex')
},
// {
// 	path: '/whitePaper',
// 	name: 'whitePaper',
// 	component: () => import('@/views/whitePaper/whitePaper')
// },
];
export default routes
