import {
	createApp
} from 'vue'
import App from './App.vue'
// 路由文件
import router from './router/index'
// vuex  导入store
import store from './store/index'
// element ui--pc
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// swiper
import 'swiper/css/swiper.css'
// vant ui--wap
import Vant from 'vant';
import 'vant/lib/index.css';

// swiper
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import PopMessage from "./components/popMessage.vue"
import BigImg from "./components/bigImg.vue"

import Axios from 'axios'
const app = createApp(App)
/* 挂载全局对象 start */
app.config.globalProperties.$http = Axios
// Axios.defaults.baseURL = '/api'

import Web3 from 'web3'
// app.config.globalProperties.Web3 = Web3

// window.Web3 = Web3

// import DApp from './index.js'
// Vue.prototype.$DApp = DApp;
// import Web3 from 'web3-init';
// import VueWeb3 from 'vue-web3';
/* 挂载全局对象 end */
// app.use(VueWeb3, { web3: new Web3(web3.currentProvider) })

// npm install vue-i18n@9
import local_en from './assets/language/local_en'
import local_zh from './assets/language/local_zh'
import { createI18n } from 'vue-i18n'

let lang = localStorage.getItem("lang");
if (!lang){
	lang = "en"
}
console.log(lang,"==============")


const i18n = createI18n({
	locale:lang,
	messages:{
		zh:local_zh,
		en:local_en
	}
})
// i18n.global.locale.value = 'en'
// i18n.global.locale = 'en'

app.use(i18n)

app.use(router)
app.use(store)
app.use(ElementPlus)
app.use(Vant)
app.use(VueAwesomeSwiper)
app.component('popMessage',PopMessage)
app.component('bigImg',BigImg)
// app.use(Web3)
app.mount('#app')