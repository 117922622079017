<template>

  <transition
      :duration="500"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
  >
    <div class="popMessage" v-show="show" style="z-index: 999;">
        <div class="popMessageMain">
            <div class="popTitle" style="cursor: pointer">
                {{popTitle}}
                <img @click="close" src="@/assets/img/sign/close.png"/>
            </div>
            <div class="popBody">
                <img class="logo" src="../assets/img/logo.png" />
                <div class="popMsg">{{msg}}</div>
                <div class="waiting">
                    <img src="../assets/img/waiting_icon.png"/>
                    <div>WAITING...</div>
                </div>
                <div class="popBottom">{{popBottom}}</div>
            </div>
        </div>
    </div>
  </transition>
</template>

<script>

    // import 'animate.css';

    export default {
        name: "popMessage",
        data() {
            return{
                show: false
            }
        },
        props: {
            popTitle: {
                type: String,
                default: ''
            },
            msg: {
                type: String,
                default: ''
            },
            popBottom: {
                type: String,
                default: ''
            },
        },
        methods: {
            open(){
                this.show = true;
            },
            close(){
                this.show = false;
            }
        },
        mounted() {
        },
    }
</script>

<style scoped>
    .popMessage{
        position: fixed;
        width: 100%;
        height: 100%;
        top:0%;
        left: 0;
        background-color: rgba(0,0,0,0.7);
    }
    .popMessageMain{
        margin: 10% auto;
        width: 30%;
        border-radius: 15px;
    }
    .popTitle{
        position: relative;
        color: white;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 5rem;
        background-color: #083f58;
        border-radius: 12px 12px 0 0;
        padding-left: 2rem;
    }
    .popTitle img{
        width: 2rem;
        height: 2rem;
        position: absolute;
        top:1.5rem;
        right: 2rem;
    }
    .popBody{
        padding: 2rem;
        background-color: #042439;
        border-radius: 0 0 12px 12px;
    }
    .logo{
        width: 30%;
        margin: 1rem 35%;
    }
    .popMsg{
        text-align: center;
        color: white;
    }
    .waiting{
        display: flex;
        width: 40%;
        height: 4rem;
        margin: 3rem 30% 1rem 30%;
        background: rgba(118, 222, 255, 0.2);
        border: 1px solid #76DEFF;
        border-radius: 20px;
    }
    .waiting img{
        width: 2rem;
        height: 2rem;
        margin: 1rem 0 1rem 2rem;
        animation: rotate 2s linear infinite;
    }
    .waiting div{
        flex: 1;
        color: white;
        line-height: 4rem;
        padding-left: 1.5rem;
        font-size: 1.5rem;
        font-weight: 700;
    }
    .popBottom{
        text-align: center;
        color: white;
    }

    @media screen and (max-width: 750px) {
        .popMessageMain {
            margin: 40% 10%;
            width: 80%;
        }
        .waiting{
            width: 60%;
            margin-left: 20%;
        }
    }
</style>