<template>

  <transition
      :duration="500"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
  >
    <div class="bigImg" @click="close" v-show="show" style="z-index: 100000;">
        <img :src="src" />
    </div>
  </transition>
</template>

<script>
    export default {
        name: "bigImg",
        data() {
            return{
                show: false
            }
        },
        props: {
            src: {
                type: String,
                default: ''
            }
        },
        methods: {
            open(){
                this.show = true;
            },
            close(){
                this.show = false;
            }
        },
        mounted() {
        },
    }
</script>

<style>
    .bigImg{
        position: fixed;
        width: 100%;
        height: 100%;
        top:0%;
        left: 0;
        background-color: rgba(0,0,0,0.7);
    }
    .bigImg img{
        width: auto;
        height: 90vh;
        margin: 5vh auto;
    }

    @media screen and (max-width: 750px) {

    }
</style>