import {
	createRouter,
	createWebHistory,
	createWebHashHistory
} from 'vue-router'
import routes from './routes' //导入router目录下的router.js

const router = createRouter({
	// mode: 'hash',
	// base: process.env.BASE_URL,
	history: createWebHashHistory(), //路由模式
	routes
})

export default router //导出
