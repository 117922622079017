import axios from 'axios'
//创建了一个axios实例  我们给这个实例编写配置 后续所有通过实例发送的请求 都收当前配置约束

let url = window.location.protocol+"//"+window.location.host+"/";

if (window.location.host=="localhost:8080"||window.location.host=="localhost:8099"){
    url = window.location.protocol+"//localhost:8086/";
}
if (window.location.host=="127.0.0.1:8080"||window.location.host=="127.0.0.1:8099"){
    url = window.location.protocol+"//localhost:8086/";
}
if (window.location.host=="192.168.179.47:8080"||window.location.host=="192.168.179.47:8099"){
    url = window.location.protocol+"//192.168.179.47:8086/";
}

if (window.location.host=="192.168.31.65:8080"||window.location.host=="192.168.31.65:8099"){
    url = window.location.protocol+"//192.168.31.65:8086/";
}


const $http = axios.create({
    baseURL: url,
    timeout: 60000,
  });

  // 添加请求拦截器
  $http.interceptors.request.use(function (config) {
    // 在发送请求之前做 些什么
  //   if (config.method.toUpperCase() == "POST") {
  //       // config.headers['Content-Type'] = 'application/XML'
		// config.headers["Content-Type"] = "application/x-www-form-urlencoded";
  //     } // /node/api/acount/addWallet wallet walletdel coins

      /**
       * && (
       *           config.url=="/node/api/acount/loginRelease" ||
       *           config.url=="/node/api/acount/addWallet" ||
       *           config.url=="/node/api/acount/wallet" ||
       *           config.url=="/node/api/acount/walletdel" ||
       *           config.url=="/node/api/acount/check" ||
       *           config.url=="/node/api/acount/coins" ||
       *           config.url=="/node/api/acount/qtoRecord" ||
       *           config.url=="/node/api/acount/transfer" ||
       *           config.url=="/node/api/acount/balanceOf" ||
       *           config.url=="/node/api/open/boxs")
       * @type {string}
       */


      var token = localStorage.getItem("auth_token");
      if ((config.method.toUpperCase() == "POST") && token  ){
          config.headers["auth"] = ""+token;
      }
    return config;
  }, function (error) {
	
    return Promise.reject(error);
  });

// 添加响应拦截器
$http.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    return response;
  }, function (error) {
    // 对响应错误做点什么
    // console.log("Net error !");
    // Notification({
    //     title: 'Error',
    //     message: '网络出现异常，请按下F5 重试',
    //     showClose: true
    // });
    return Promise.reject(error);
  });


  export default $http