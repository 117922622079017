import Web3 from 'web3'
import Contract from '@/web3/config/abi'
// console.log(Contract)

// const nodeHost = "http://127.0.0.1:7777";
 // USDT
const usdt_contract_address = Contract.usdt_contract_address;

//SHIELD代币合约
const sheld_contract_abi = Contract.sheld_contract_abi;
const sheld_contract_address = Contract.sheld_contract_address;


//用户模块
const user_contract_abi = Contract.user_contract_abi;
const user_contract_address = Contract.user_contract_address;


// 特工币

const agent_contract_abi = Contract.agent_contract_abi;
const agent_contract_address = Contract.agent_contract_address;


// NFT

const nft_contract_abi = Contract.nft_contract_abi;
const nft_contract_address = Contract.nft_contract_address;


// 案件

const case_contract_abi = Contract.case_contract_abi;
const case_contract_address = Contract.case_contract_address;

let web3 = null;

/**
 * @description 创建web3实例
 * @returns web3实例
 */
export const createWeb3 = async () => {
    // if (!web3){
    //     web3 = ((window).ethereum);
    // }
    // return  web3;
    // data-seed-prebsc-2-s3.binance.org:8545/
    const web3 = await window.ethereum.enable().then(() => {
        // const web3 = new Web3(window.ethereum || window.web3.currentProvider ) //|| window.web3.currentProvider

        const web3 = new Web3(window.ethereum || window.web3.currentProvider ) //|| window.web3.currentProvider
        return web3
    }, () => {
        console.warn('用户拒绝了授权');
        return null
    })
    return web3

}

/**
 * @dev 获取当前账户 ,已 不用 方式实现
 * @returns 当前账户
 */
export const getAccount = async () => {
    // const web3 = await createWeb3()
    // const [account] = await web3.eth.getAccounts()
    //console.log(" ========== ",account)
    return "0x";
}
/**
 * @dev 获取当前余额
 * @returns 当前余额
 */
export const getBalance = async () => {
    const web3 = await createWeb3()
    const [account] = await web3.eth.getAccounts()
	const balance = await web3.eth.getBalance(account)
    return Number(balance)/Math.pow(10,18)
}



/**
 * @dev 监听账户变动
 * @param func 回调函数
 * @returns 当前账户地址
 */
export const listenAccountsChanged = function(func){
    return window.ethereum.on('accountsChanged', (accountsy) => {
        const [account] = accounts
        func();
        return account
    })
}

/**
 * 
 * @description 创建合约
 * @param abi 合约二进制接口
 * @param address 合约地址
 * @returns 合约实例和当前账户地址
 */
export const createContract = async (sheld_contract_abi, sheld_contract_address) => {
    const web3 = await createWeb3()
    const contract = new web3.eth.Contract(sheld_contract_abi, sheld_contract_address)
    const [account] = await web3.eth.getAccounts()
    return [contract, account]
}

/**
 * @description 创建合约实例
 * @returns 合约实例及当前账户地址
 */
export const contractInstance = async () => {
    const [contract, account] = await createContract(sheld_contract_abi, sheld_contract_address)
    return [contract, account]
}




/**
 * 
 * @description 创建合约
 * @param abi 合约二进制接口
 * @param address 合约地址
 * @returns 合约实例和当前账户地址
 */
export const UserCreateContract = async (user_contract_abi, user_contract_address) => {
    const web3 = await createWeb3()
    const contract = new web3.eth.Contract(user_contract_abi, user_contract_address)
    const [account] = await web3.eth.getAccounts()
    return [contract, account]
}

/**
 * @description 创建合约实例
 * @returns 合约实例及当前账户地址
 */
export const UserContractInstance = async () => {
    const [contract, account] = await UserCreateContract(user_contract_abi, user_contract_address)
	// console.log(contract, account)
    return [contract, account]
}


//特工币合约

/**
 * 
 * @description 创建合约
 * @param abi 合约二进制接口
 * @param address 合约地址
 * @returns 合约实例和当前账户地址
 */
export const AgentCreateContract = async (agent_contract_abi, agent_contract_address) => {
    const web3 = await createWeb3()
    const contract = new web3.eth.Contract(agent_contract_abi, agent_contract_address)
    const [account] = await web3.eth.getAccounts()
    return [contract, account]
}

/**
 * @description 创建合约实例
 * @returns 合约实例及当前账户地址
 */
export const AgentContractInstance = async () => {
    const [contract, account] = await AgentCreateContract(agent_contract_abi, agent_contract_address)
    return [contract, account]
}


//nft合约

/**
 * 
 * @description 创建合约
 * @param abi 合约二进制接口
 * @param address 合约地址
 * @returns 合约实例和当前账户地址
 */
export const NftCreateContract = async (nft_contract_abi, nft_contract_address) => {
    const web3 = await createWeb3()
    const contract = new web3.eth.Contract(nft_contract_abi, nft_contract_address)
    const [account] = await web3.eth.getAccounts()
    return [contract, account]
}

/**
 * @description 创建合约实例
 * @returns 合约实例及当前账户地址
 */
export const NftContractInstance = async () => {
    const [contract, account] = await NftCreateContract(nft_contract_abi, nft_contract_address)
    return [contract, account]
}



//USDT授权

/**
 * 
 * @description 创建合约
 * @param abi 合约二进制接口
 * @param address 合约地址
 * @returns 合约实例和当前账户地址
 */
export const USDTCreateContract = async (sheld_contract_abi, usdt_contract_address) => {
    const web3 = await createWeb3()
    console.log(web3,"-0000----")
    const contract = new web3.eth.Contract(sheld_contract_abi, usdt_contract_address)
    const [account] = await web3.eth.getAccounts()
    return [contract, account]
}

/**
 * @description 创建合约实例
 * @returns 合约实例及当前账户地址
 */
export const USDTContractInstance = async () => {
    const [contract, account] = await USDTCreateContract(sheld_contract_abi, usdt_contract_address)
    return [contract, account]
}




//案件合约

/**
 * 
 * @description 创建合约
 * @param abi 合约二进制接口
 * @param address 合约地址
 * @returns 合约实例和当前账户地址
 */
export const CaseCreateContract = async (case_contract_abi, case_contract_address) => {
    const web3 = await createWeb3()
    const contract = new web3.eth.Contract(case_contract_abi, case_contract_address)
    const [account] = await web3.eth.getAccounts()
    return [contract, account]
}

/**
 * @description 创建合约实例
 * @returns 合约实例及当前账户地址
 */
export const CaseContractInstance = async () => {
    const [contract, account] = await CaseCreateContract(case_contract_abi, case_contract_address)
    return [contract, account]
}




/**
 * @description 创建合约实例
 * @returns 合约实例及当前账户地址
 */
export const TradingCreateContract = async (user_contract_abi, user_contract_address) => {
    const web3 = await createWeb3()
    const contract = new web3.eth.Contract(user_contract_abi, user_contract_address)
    const [account] = await web3.eth.getAccounts()
    return [contract, account]
}
export const TradingContractInstance = async () => {
    const [contract, account] = await TradingCreateContract(Contract.nftTrade_contract_abi, Contract.nftTrade_contract_address)
    // console.log(contract, account)
    return [contract, account]
}

export const OpenBoxsCreateContract = async (user_contract_abi, user_contract_address) => {
    const web3 = await createWeb3()
    const contract = new web3.eth.Contract(user_contract_abi, user_contract_address)
    const [account] = await web3.eth.getAccounts()
    return [contract, account]
}

export const OpenBoxsContractInstance = async () => {
    var openboxs = [{ "inputs": [ { "internalType": "address", "name": "_renter", "type": "address" }, { "internalType": "uint256", "name": "_keys", "type": "uint256" }, { "internalType": "uint256", "name": "_tokenId", "type": "uint256" }, { "internalType": "uint8", "name": "_nftRole", "type": "uint8" }, { "internalType": "bytes", "name": "prefix", "type": "bytes" }, { "internalType": "uint8", "name": "_v", "type": "uint8" }, { "internalType": "bytes32", "name": "_r", "type": "bytes32" }, { "internalType": "bytes32", "name": "_s", "type": "bytes32" } ], "name": "openBox", "outputs": [ { "internalType": "bool", "name": "", "type": "bool" } ], "stateMutability": "nonpayable", "type": "function" }];
    const [contract, account] = await OpenBoxsCreateContract(openboxs, "0xC368C6bEb9F9de4595CFF0852794e64f6C8cC9b4")
    // console.log(contract, account)
    return [contract, account]
}

/**
 * @description 创建合约实例
 * @returns 合约实例及当前账户地址
 */
export const BoxCreateContract = async (user_contract_abi, user_contract_address) => {
    const web3 = await createWeb3()
    const contract = new web3.eth.Contract(user_contract_abi, user_contract_address)
    const [account] = await web3.eth.getAccounts()
    return [contract, account]
}
export const BoxContractInstance = async () => {
    const [contract, account] = await BoxCreateContract(Contract.nft_contract_abi, Contract.box_contract_address)
    // console.log(contract, account)
    return [contract, account]
}