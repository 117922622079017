import {createWeb3, USDTContractInstance, UserContractInstance} from '@/web3/api/common'
import Contract from '@/web3/config/abi'
import * as Console from "console";

import { BigNumber } from 'bignumber.js'

/**
 * 
 * @returns 购买盲盒
 */
export const buyBoxs = async (nftRole,payType) => {
    const [contract, account] = await UserContractInstance()
    return await contract.methods.buyBoxs(nftRole,payType).send({ from: account })
}



/**
 * 
 * @returns 购买NFT
 */
export const buyNfts = async (nftRole , payType) => {
    const [contract, account] = await UserContractInstance()
    return await contract.methods.buyNfts(nftRole , payType).send({ from: account })
}


/**
 * 
 * @returns 购买SHEILD代币
 */
export const sheldSwap = async (amount , payType) => {
    var jdd = 1000000; //  1000000000000000000
    // var jdd = 1000000000000000000;

    var fv = 0.0001; //  代币 价格
    var u_ = (amount * fv) * jdd;
    var __value = 0 ;
    if (payType=='1'){
        __value = 0 ;
        fv = 0.001;
        u_ = (amount * fv) * jdd;
        // USDT
        const [contract, account] = await USDTContractInstance();

        var cc = await contract.methods.allowance(account,Contract.user_contract_address).call();
        
        if (cc<u_){
            var uac = 10000 * 1000000000000000000;
            var web3 = await createWeb3()
            console.log(web3);
            var u1 = new BigNumber(uac);
            //var u1 =  (uac);
            var tmp = await  contract.methods.approve(Contract.user_contract_address,u1).send({from:account});

            // status: true
            if (tmp.status){

            }else{
                return  false;
            }
        }
    }else{
        __value = u_ ;
    }
    var nf = amount * jdd;
    console.log(nf,payType," ==============>>>>>>>>>")
    const [contract, account] = await UserContractInstance()


    if (payType=="0"){
        // uint256 _value = (coinPrice[_payType] * _amount) / fdd; 100000 000000000
        __value = (100000000000000 * amount * jdd) / jdd;
    }
//    BigNumber.from(input).mul(BigNumber.from(10).pow(decimals));

    // return await contract.methods.sheldSwap(new BigNumber(nf+"") , payType).send({ from: account, value: new BigNumber(__value+"") })
    return await contract.methods.sheldSwap( (nf+"") , payType).send({ from: account, value:  (__value+"") })
}


/**
 * 购买NFT
 * @param role
 * @returns {Promise<void>}
 */
export const buyNFTS = async (role) => {
    var __value = 100000000000001;
    const [contract, account] = await UserContractInstance()
    console.log(role," ==== ",__value)
    return await contract.methods.buyNfts( role , 0).send({ from: account, value:  (__value) })
}

/**
 * 购买 BOXS
 * @param role
 * @returns {Promise<void>}
 */
export const buyBOXS = async (role) => {
    const [contract, account] = await UserContractInstance()
    var __value = 100000000000000; // 1000
    //console.log(role," ==== ")
    return await contract.methods.buyBoxs( role , 0).send({ from: account, value:  (__value) })
}

/**
 *
 * @returns 特工币兑换
 */
export const swap = async (inAmount , outAmount) => {
    const [contract, account] = await UserContractInstance()
    return await contract.methods.swap(inAmount , outAmount).send({ from: account })
}




