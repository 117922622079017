import {
	createStore
} from 'vuex'
export default createStore({
	state: {
		isCollapse: true, // 是否折叠
		nav1Id: "1", //一级菜单状态
		nav2Id: "1", //二级菜单状态
		nav3Id: "1", //三级菜单状态
		myAccountStatus: true, //myAccount 的链接状态
		isPaymentPop:false,//market 的支付弹窗
		PaymentPopObj:{},//market 的支付数据
		isSearchCriteria: [], //搜索条件
		listArrys: true, //右侧是否有数据
		listSelectValue1:'1',//筛选条件1 时间和间隔
		listSelectValue2:'1',//筛选条件2 发送 接收 全部
		listSelectValue3:'0',//筛选条件3 投票
		listSelectValue4:'1',//筛选条件4 世界 
		isExport:false,//出口按钮是否展示
		createCasePop:false,//添加案例弹窗
		isAgentPop:false,//配备弹窗  4-2 agent 弹窗
		isCraftPop:false,//配备弹窗  4-2 craft 弹窗
		isWapRight: false, // 移动端右侧导航是否显示
		loading:false, //底部加载中
		nomore:false,	//底部没有内容

		address:null, // 钱包地址
		authToken:null, // 账号TOKEN

	},
	mutations: {
		//底部加载中
		addloading(state, newData){
			state.loading = newData;
		},
		//底部没有内容
		addnomore(state, newData){
			state.nomore = newData;
		},
		//是否折叠
		changeCollapse(state, newData) {
			state.isCollapse = newData;
		},
		//修改一级菜单状态
		changeNav1id(state, newData) {
			// debugger
			state.nav1Id = newData;
			state.isPaymentPop = false
		},
		//修改二级菜单状态
		changeNav2id(state, newData) {
			state.nav2Id = newData;
			state.isPaymentPop = false;
			state.createCasePop = false;
		},
		//修改二级菜单状态
		changeNav3id(state, newData) {
			state.nav3Id = newData;
		},
		//修改  myAccount 的链接状态
		changeMyAccountStatus(state, newData) {
			state.myAccountStatus = newData;
		},
		//修改 搜索条件
		changeSearchCriteria(state, newData) {
			state.isSearchCriteria = newData;
		},
		//删除 搜索条件
		removerSearchCriteria(state) {
			state.isSearchCriteria = [];
		}, 
		//修改 右侧列表数据
		changelistArrys(state, newData) {
			state.listArrys = newData;
		},
		//market 的支付弹窗
		changeIsPaymentPop(state, newData) {
			state.isPaymentPop = newData;
		},
		//market 的支付数据
		changeIsPaymentPopObj(state, newData) {
			state.PaymentPopObj = newData;
		},
		//筛选条件1结果
		changelistSelectValue1(state, newData) {
			state.listSelectValue1 = newData;
		},
		//筛选条件2结果  
		changelistSelectValue2(state, newData) {
			state.listSelectValue2 = newData;
		},
		
		//筛选条件3结果
		changelistSelectValue3(state, newData) {
			
			state.listSelectValue3 = newData;
		},
		//筛选条件4结果
		changelistSelectValue4(state, newData) {
			state.listSelectValue4 = newData;
		},
		//出口按钮是否展示
		changeisExport(state, newData) {
			state.isExport = newData;
		},
		//添加案例弹窗
		changecreateCasePop(state, newData) {
			state.createCasePop = newData;
		},
		//配备弹窗  4-2 agent 弹窗
		changeisAgentPop(state, newData) {
			state.isAgentPop = newData;
		},
		//配备弹窗  4-2 craft 弹窗
		changeisCraftPop(state, newData) {
			state.isCraftPop = newData;
		},
		//移动端右侧导航是否显示
		changeisWapRight(state, newData) {
			state.isWapRight = newData;
		},

		// 钱包地址赋值
		changeAdress(state,newData){
			state.address = newData;
		},
		// 钱包地址赋值
		changeAuthToken(state,newData){
			state.authToken = newData;
		}



	},
	//Actions用于处理异步任务
	actions: {

	},
	modules: {}
})



/**
 * 表单：回车到下一项
 * @param {表单名称} formName
 * @param {提交方法}} callback
 */
export function nextFocus(formName, callback) {
// 获取到表单的所有input输入框
	const inputDoms = document.querySelectorAll(`#${formName} .el-input__inner`);
// 遍历input输入框
	inputDoms.forEach((item, index) => {
// 添加索引
		item.setAttribute("data-index", index);
// 添加事件处理程序
		item.addEventListener('keyup', function (e) {
			var ev = typeof event != "undefined" ? window.event : e;
			if (ev.keyCode == 13) {
// 获取当前录入input的索引值
				const index = ev.target.getAttribute("data-index");
				const nextIndex = parseInt(index) + 1;
				const length = inputDoms.length;
// 如果不是最后一个，则跳到下一项，如果是，走提交
				if (nextIndex < length) {
					inputDoms[nextIndex].focus();
				} else {
					if (typeof callback === "function") {
						callback();
					}
				}
			}
		});
	});
}