<template>
	<router-view></router-view>

</template>

<script>
	import {onMounted,onBeforeMount,ref,reactive,onBeforeUnmount} from 'vue'
	import {apigit} from '@/api/api.js'
	import {getAccount,createWeb3,getBalance} from '@/web3/api/common'
	import {symbol,balanceOf,name,decimals,totalSupply,allowance,approve} from '@/web3/api/shield'
	import * as agent from '@/web3/api/agent'
	import * as nft from '@/web3/api/nft'
	import * as user from '@/web3/api/user'
	import {createCase} from '@/web3/api/case'

	
	export default {
		name: 'App',
		components:{},
		setup(){
			
			
			
			return{
				
			}
		}
		
		
	}
</script>

<style>
	@import url('assets/css/reset.css');
</style>

