module.exports = {
    // 首页
    HOME:'HOME',
    ECOVERSE:"ECOVERSE",
    SIGN_UP:"SIGN UP",
    SIGN_IN:"SIGN IN",
    We:"META S.H.I.E.L.D.",//
    protect:"",
    Metaverse:'',//守护元宇宙
    S_1:"<span>Security&nbsp;Honest&nbsp;Insurance,&nbsp;Ecoverse&nbsp;and&nbsp;Logistics&nbsp;Division</span> ",//
    JOIN_US:"JOIN US",
    INVITE_MEMBER:"INVITE MEMBER",
    WHITE_PAPER:"WHITE PAPER",
    S_2:"Exploring the METAshield",
    GET_YOUR_TOKENS:"<p>Get your</p><p class=\"page2TitleP2\">Tokens</p>",
    RULES:'RULES',
    S_3:'Anyone can receive MetaShield as compensation if they suffer any loss.',
    S_4:'Anyone who wants to be protected by the MetaShield shall hold MetaShield to get the protected.',
    S_5:'Users can store their MetaShield token in their account and then designate their wallets and NFT assets\n' +
        '                to be protected in a virtual “Vault”, only the address linked to the Vault can be protected, the\n' +
        '                protection level is related to the amount of MetaShield token they held in the account.',
    ADD_WALLET:"ADD WALLET",
    BUY_TOKENS:"BUY TOKENS",
    S_6:"<p>WHAT IS</p><p class=\"page3TitleP2\">METASHIELD</p>",
    S_7:'MetaShield is a general protection and compensation plan to maintain the peace in Metaverse.\n' +
        '            MetaShield DAO is established to create, and pioneer on-chain metaverse protection that covers asset losses\n' +
        '            in NFT and gaming. It onboards all people who are willing to help in keeping the metaverse safe.',
    MORE:"more",
    S_8:"<p>WE RESIST THE RISK<br/>FROM</p>",
    S_9:"Contract Exploit Cover",
    S_10:"Where a defi pool or smart contract is exploited by hackers, the user cannot claim assets in the\n" +
        "                  Vault back.",
    S_11:"Rug Pull Cover",
    S_12:"Where assets in the Vault are paid as contribution but did not receive promised tokens/share of\n" +
        "                  tokens/NFTs.",
    S_13:"Bridge Loss Cover",
    S_14:"Where the assets in the Vault are lost when the bridging to different networks, due to a hack or\n" +
        "                  exploit of the network.",
    S_15:"Private Key Loss Cover",
    S_16:"Where the assets in the Vault are lost due to hacks, phishing or any unintentional use. (MetaShield\n" +
        "                  cannot recover private key, instead we cover the financial loss)",
    S_17:"NFT Loss Cover",
    S_18:"Where the NFT assets in the Vault are lost due to phishing or any unintentional use in Opensea,\n" +
        "                  Rarible accounts. (MetaShield cannot recover NFT, instead we cover the financial loss)",
    S_19:"Other Loss",
    S_20:"Upon DAO decision, other potential losses can be added.",
    GUILD_LICENSE:"GUILD LICENSE",
    S_21:"Origin Of Agent NFT",
    S_22:"Agents are the protector of Metaverse. <br/>\n" +
        "            They investigate the adequacy of the claimants’ evidence through on-chain data and online traces.<br/>\n" +
        "            Agents’ goal is to determine the sources of the claimants’ loss – whether the loss is caused by malicious attacks, loopholes, or intentional cheating.",
    S_23:"GET",
    S_24:"<p>WHO ARE USING</p><p>METASHIELD</p>",
    S_25:"All Metaverse users are welcomed to join MetaShield community. As long as users hold $SHLD. They would have\n" +
        "            the right to submit a claim for compensation by staking $SHLD under following situation:\n" +
        "            <BR/>\n" +
        "            1. Vulnerability<BR/>\n" +
        "            2. Fraud<BR/>\n" +
        "            3. Hacking<BR/>\n" +
        "            4. Rug Pull<BR/>",
    MORE_SCENE:"MORE SCENE",
    S_26:"<p>OUR</p><p>SERVICES</p>",
    S_27:"Metaverse Safety Insurance",
    S_28:"Contract Utilization Protection",
    S_29:"When defi pool or smart contract are used by hackers, users cannot request a refund.",
    S_30:"Bridge loss  Insurance",
    S_31:"Networks are improperly used due to hacker attack, when the bridge connects to different networks, assets in the vault get lost.",
    S_32:"Private key loss Insurance",
    S_33:"Insured addresses get any hacker attack, phishing or not on purpose financial loss. (If insured user loss/ forget the private key itself, MetaShield cannot restore your private key but will compensate for the loss.",
    S_34:"NFT lost Insurance",
    S_35:"Your insured NFT assets get lost because of hacker attacks,phishing, or any accident. (Because every NFT is unique, MetaShield cannot build the same NFT for users, but we will compensate for all the financial loss.)",
    S_36:"Loss of game assets",
    S_37:"Your insured Game assets get lost because of hacker attacks, phishing, or any accident. (Because every game asset is unique, MetaShield cannot build the same game assets for users, but we will compensate for all the financial loss.)",
    S_38:"Rug Pull Protection Insurance",
    S_39:"When insurance is paid but user did not receive the token/ token amount/ NFT.",
    S_40:"<p>OUR</p><p>ROADMAP</p>",
    S_41:"Understand our grand plan.",
    WELCOME:"WELCOME",
    COMMUNITY:"COMMUNITY",
    TOU:"TO OUR USER",
    S_42:"Build the<span>metaverse</span>with members and agents.",
    METASHIELD_COMMUNITY:"METASHIELD COMMUNITY",
    S_43:"There are five unions for agents in MetaShield. Their job is to protect other users in MetaShield. Every agent is proud to be a member of one of the listed unions: Angry Fire、Glory Union、Rage Poseidon、Fairy Forest and Ancient Blazer.",
    COMMUNITY_GOAL:"COMMUNITY GOAL",
    S_44:"We put every effort to help our community for a better future. Our goal is to create an ecosystem in the MetaShield community, where users are allowed to claim for events in Metaverse and what happened to them in the real world.",
    COMMUNITY_CAPABILITY:"COMMUNITY CAPABILITY",
    S_45:"We put freedom in the community as a top priority. Users can do plenty of different things in the MetaShield community, a startup on the blockchain would be a perfect example where users might be able to get protection for their little business from MetaShield and they can seek help from the community for any trouble.\n",
    S_46:"<p>Our</p><p>Team</p>",
    S_47:"Operations and developers",
    S_48:"<p>COOPERATIVE</p><p>PARTNERS</p>",
    S_49:"Thank you for your contribution to the <span>METASHIELD</span>",
    CONTACT_US:"CONTACT US",


    VALUE_MARKET:"VALUE",

    "selecct_lang":"Click to choose",
    "select_post":"Click to choose",


    rechange_ti:"Deposit USDT/USDC/BUSD",
    "rechagneu_bto":"The successfully recharged USDC/BUSD will be converted into USDT at 1:0.999 and deposited into the account",
    "rechangeu_msg":"Please note that only supported network on Metashield platform is shown. If you deposit via another network your assets maybe lost. Send only USDT/USDC/BUSD to this deposit address.",
    de_tis:"This address does not support deposit of non-fungible token , please go to NFT page to deposit NFT.",

}



























